import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";
import VueAnimateOnScroll from "vue3-animate-onscroll";
import mitt from "mitt";
import vClickOutside from "click-outside-vue3";
import Clipboard from "v-clipboard";
import currencyFormat from "@/utils/formatCurrency";

// import "vue-toast-notification/dist/theme-sugar.css";
import "vue-toast-notification/dist/theme-bootstrap.css";
import "vue3-toastify/dist/index.css";
import "./index.css";
import "./registerServiceWorker";
import "./assets/tailwind.scss";

const emitter = mitt();
// ...
function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? "-" : "";

  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString();
  let j = i.length > 3 ? i.length % 3 : 0;

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : "")
  );
}

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (
        to.matched
          .find((record) => record.meta.roles)
          .meta.roles.includes(store.getters.userType.toLowerCase())
      ) {
        next();
        return;
      } else {
        next("/products");
        return;
      }
    } else {
      next("/login?redirected_from=" + to.fullPath);
      return;
    }
  } else {
    next();
  }
});

createApp(App)
  .use(store)
  .use(VueToast, {
    // One of the options
    position: "top",
    duration: 900000,
  })
  .use(router)
  .use(VueAnimateOnScroll)
  .use(Clipboard)
  .provide("formatMoney", formatMoney)
  .provide("currencyFormat", currencyFormat)
  .provide("emitter", emitter)
  .use(vClickOutside)
  .mount("#app");
